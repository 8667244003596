import React from "react";
import ppaLogo from "../../resources/images/ppa-footer-logo.svg";
import facebookIcon from "../../resources/images/footer-fb.png";
import youtubeIcon from "../../resources/images/footer-yt.png";
import linkedInIcon from "../../resources/images/footer-ln.png";
import telegramIcon from "../../resources/images/footer-tg.png";
import instagramIcon from "../../resources/images/footer-ig.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="bg-[#3940A0]">
            <div className="flex items-center py-[3vw] ml-[7vw] pb-[1vw] max-sm:hidden">
                <img
                    className="w-[3rem] h-[3rem] mr-[1vw] max-sm:w-[2rem] max-sm:h-[2rem]"
                    src={ppaLogo}
                    alt=""
                />
                <p className="font-PoppinsRegular text-[1.5vw] text-white">Programming Pathshala</p>
            </div>
            <div className="flex justify-between my-[1.5vw] mx-[3vw] mr-[0vw] pr-[10vw] pl-[4vw] max-sm:flex-col max-sm:justify-around max-sm:pr-[2vw]">
                <div className="flex w-[45vw] max-sm:flex-col max-sm:w-[78vw]">
                    <div className="flex flex-col max-sm:mt-[6vw]">
                        <p className="font-PoppinsRegular text-[1.2vw] py-[1vw] text-[#ffff] font-semibold tracking-wider max-sm:text-[4vw]">
                            COURSES
                        </p>

                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/crack-coding-interviews`}
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Renaissance for Top Tech Companies
                            </p>
                        </a>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/low-level-design`}>
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Low Level Design and Concurrency
                            </p>
                        </a>
                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/dsa-and-algorithms-essentials`}
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Crash Course in Data Structures & Algorithms
                            </p>
                        </a>
                        <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                            Backend Development with Java Spring Boot
                        </p>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/begin-with-c++`}>
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Begin with Programming in C++
                            </p>
                        </a>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/begin-with-python`}>
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Become a Python Expert
                            </p>
                        </a>
                    </div>
                    <div className="flex flex-col ml-[4vw] max-sm:mt-[0vw] max-sm:ml-[0vw]">
                        <span className="mt-[4vw] max-sm:mt-[0vw]"></span>
                        <a href="/begin-with-python">
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Become a Python Expert
                            </p>
                        </a>
                        <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                            System Design for Professionals
                        </p>
                        <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                            Be a Data Engineer
                        </p>
                        <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                            Full Stack Development in MERN
                        </p>
                    </div>
                </div>

                <div className="flex justify-between pt-[4vw] max-sm:p-[0vw] max-sm:ml-[0vw] max-sm:pt-[5vw] max-sm:w-auto">
                    <div className="flex flex-col pl-[5vw] w-[20vw] max-sm:pl-[0vw]">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                About Us
                            </p>
                        </a>
                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/crack-coding-interviews/stories`}
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Success Stories
                            </p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://blogs.programmingpathshala.com/"
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Blogs
                            </p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://events.programmingpathshala.com/"
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Events
                            </p>
                        </a>
                    </div>

                    <div className="pl-[2vw] flex flex-col justify-start max-sm:px-[3vw]">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/nG8FArKkwNEJucqE7"
                            className="links-font-style"
                            to=""
                        >
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Get Scholarships
                            </p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/jsepbL2id7gpkSLeA"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                Teach with Us
                            </p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://community.programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                {" "}
                                Join our Community
                            </p>
                        </a>
                        <div className="policies">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/terms-and-conditions`}
                                className="links-font-style"
                                to=""
                            >
                                <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                    Terms and Conditions
                                </p>
                            </a>
                            <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/refund-policy`}>
                                <p className="font-PoppinsRegular text-[#ffff] text-[1vw] py-[1vw] max-sm:text-[3.4vw] max-sm:py-[2.5vw]">
                                    Refund Policy
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-start pl-[77vw] pt-[1vw] max-sm:py-[3vw] max-sm:px-[5vw] max-sm:justify-center">
                <a
                    href="https://www.facebook.com/Programmingpathshala.1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="w-[2.5rem] h-[2.5rem] mr-[1vw] max-sm:w-[9vw]"
                        src={facebookIcon}
                        alt=""
                    />
                </a>
                <a
                    href="https://www.youtube.com/channel/UC4uPfwRHPbYlmjNMiKIdWNg"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="w-[2.5rem] h-[2.5rem] mr-[1vw] max-sm:w-[9vw]"
                        src={youtubeIcon}
                        alt=""
                    />
                </a>
                <a
                    href="https://www.linkedin.com/school/programming-pathshala/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img
                        className="w-[2.5rem] h-[2.5rem] mr-[1vw] max-sm:w-[9vw]"
                        src={linkedInIcon}
                        alt=""
                    />
                </a>
                <a
                    href="https://t.me/programmingpathshala"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="w-[2.5rem] h-[2.5rem] mr-[1vw] max-sm:w-[9vw]"
                        src={telegramIcon}
                        alt=""
                    />
                </a>
                <a
                    href="https://www.instagram.com/programmingpathshala/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="w-[2.5rem] h-[2.5rem] mr-[1vw] max-sm:w-[9vw]"
                        src={instagramIcon}
                        alt=""
                    />
                </a>
            </div>
            <div className="py-[1vw]">
                <p className="text-white font-PoppinsRegular font-normal text-left text-[1vw] ml-[7vw] mt-[-4vw] max-sm:text-[3vw] max-sm:text-center pt-[2vw] pb-[6vw] ">
                    All rights reserved with @Programming Pathshala
                </p>
            </div>
        </div>
    );
};

export default Footer;
