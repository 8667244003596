import Slider from "react-slick";
import arrowPrev from "../../resources/images/left-arrow-blue.png";
import arrowNext from "../../resources/images/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreCourses.css";
import { courseCardDetails } from "./ExploreCoursesCardDetails";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const ExploreCourses = (props) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {courseCardDetails &&
                    courseCardDetails.map((data, index) => (
                        <a
                            href={data.clickable ? data.courseLink : ""}
                            className="!w-[90%] bg-white border border-[#d9d9d9] rounded-[1.3vw] overflow-hidden relative cursor-pointer mb-[5vw] h-[28vw] max-sm:h-[70vw] max-sm:rounded-[3.6vw]"
                            key={index}
                        >
                            <div className="w-full h-[15vw] mb-[1vw] max-sm:h-[40vw]">
                                <img
                                    className="w-full h-full object-cover"
                                    src={data.courseBannerImg}
                                    alt=""
                                />
                            </div>
                            <p className="text-[#353535] text-left font-PoppinsRegular text-[1.1vw] p-[1vw] max-sm:text-[3.1vw] max-sm:p-[3vw]">
                                {data.courseDescription}
                            </p>
                            <div className="flex justify-center items-center font-PoppinsSemiBold bg-white border-none absolute bottom-[5%] pl-[1.5vw] text-[#444bab] text-center">
                                <p className="text-[1.25vw] max-sm:text-[3.4vw] max-sm:px-[3vw]">
                                    Know More
                                </p>
                            </div>
                        </a>
                    ))}
            </Slider>
        );
    };

    return (
        <div className="courses-wrapper">
            <p className="font-PoppinsSemiBold mb-[2vw] text-[2vw] max-sm:text-[4vw] text-center">
                {" "}
                Explore Our Other Courses
            </p>
            {getSlidingStories()}
        </div>
    );
};

export default ExploreCourses;
